<script>
export default {
  data(){
    return {
      reserveSet:{},
    }
  },
  methods:{
    checkTempReserveSet(){
      console.log('checkTempReserveSet 開始')
      if(localStorage.getItem('reserveSet')){
        this.reserveSet = JSON.parse(localStorage.getItem('reserveSet'));
        if(this.reserveSet.event){
          this.$router.push({
          path:'/reserve',
        })
        }
      }
    },
    mailQueryGet(){
      if(this.$route.query.name){
        // const temp = this.$route.query.reserveset.split('**');
        this.reserveSet.name = this.$route.query.name;
        this.reserveSet.mailAddress = this.$route.query.mailAddress;
        localStorage.setItem('reserveSet',JSON.stringify(this.reserveSet));
      }
    },
  },
  created(){
    this.checkTempReserveSet();
    this.mailQueryGet();
    this.$router.push({
      path:'/reserve',
    })
  }
}
</script>