import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Set from '../views/Set.vue'
import thankyou from '../components/thankyou.vue'
// import AddReserveDay from '../components/AddReserveDay.vue'
import Register from '../views/Register.vue'
import Payment from '../views/Payment.vue'
import CreateUser from '../views/CreateUser.vue'
import registerThankyou from '../views/registerThankyou.vue'
import CreateUserManual from '../views/CreateUserManual.vue'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    name:'Home',
    component: Home
  },
  {
    path:'/set/',
    name:'Set',
    component: Set,
  },
  {
    path:'/thankyou/',
    name:'thankyou',
    component:thankyou,
  },
  // {
  //   path:'/AddReserveDay/',
  //   name:'AddReserveDay',
  //   component:AddReserveDay,
  // },
  {
    path:'/Register/',
    name:'Register',
    component:Register,
  },
  {
    path:'/Payment/',
    name:'Payment',
    component:Payment,
  },
  {
    path:'/CreateUser/',
    name:'CreateUser',
    component:CreateUser,
  },
  {
    path:'/registerThankyou/',
    name:'registerThankyou',
    component:registerThankyou,
  },
  {
    path:'/CreateUserManual/',
    name:'CreateUserManual',
    component:CreateUserManual,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
