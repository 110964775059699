<template>
  <v-container>
    <v-row align-content="center">
      <v-col align="center">
        <v-card max-width="400px">
          <v-card-title align="left">
            予約日：{{reserveSet.event.date}}<br>
            時間帯：{{reserveSet.event.name}}
          </v-card-title>
          <v-card-text align="left">
            お名前：{{reserveSet.name}}<br>
            メールアドレス：{{reserveSet.reserverMailAddress}}<br>
            希望コース：{{ reserveSet.item.title }}<br>
            価格： {{ reserveSet.item.price }}円<br>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-dialog v-model="paymentCheckDialog" width="500" persistent disabled>
              <template v-slot:activator="{ on,attrs }">
                <v-btn text @click="paymentCheck" v-bind="attrs" v-on="on">
                  予約する
                </v-btn>
              </template>
              <v-card id="payment-form">
                <v-card-title>
                  {{reserveSet.item.title}} 料金：{{reserveSet.item.price}} 円
                </v-card-title>
                <v-card-text id="card-container"></v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn v-if="!squareToken" @click="cardButtonClick" :disabled="cardButton">
                    お支払い
                  </v-btn>
                  <v-btn v-else @click="paymentButtonClick"  :disabled="cardButton">お支払い</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn @click="paymentCancel">
                    戻る
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
              <div id="payment-status-container"></div>
            </v-dialog>

            <v-btn text @click="backComp">
              キャンセル
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-overlay :value="overlay">
          <v-progress-circular :width="7" :size="70" color="primary" indeterminate></v-progress-circular>
        </v-overlay>
      </v-col>
    </v-row>
  </v-container>


</template>

<script>
import { SubReserveTemp,AddReserve,SubReserve,LoadApiData,createSquareToken, } from "../modules/OperationFirebase";
// import { SubReserveTemp, SubReserve, LoadApiData } from "../modules/OperationFirebase";
import { zoom, sendmail, sendMailTemplate, paymentSquare, paymentSquareActual, paymentSquareCancel } from "../modules/CloudFunctions";
// import { zoom, sendmail, sendMailTemplate, paymentSquare } from "../modules/CloudFunctions";

export default {
  data(){
    return {
      overlay:false,
      keys:{},
      paymentResult:false,
      cardButton: false,
      dialog:false,
      paymentCheckDialog:false,
      paymentCard:false,
      squareToken:null,
    }
  },
  props:['reserveSet'],
  methods:{
    async paymentCheck(){
      this.keys = await LoadApiData(this.reserveSet.uid);
      this.keys = this.keys.data()
      console.log('keys', this.keys, this.keys.SquareTestMode)
      console.log("決済の確認", this.keys.SquarePayment)
      if (this.keys.SquarePayment){
        this.paymentCheckDialog = true;
        // this.paymentDialog();
      } else {
        this.nextComp();
      }
    },
    paymentDialog(){
      // this.firstLoad();
    },
    paymentCancel(){
      window.location.reload(false)
    },
    async nextComp(){
      console.log("nextComp")
      this.overlay = true;
      this.paymentCheckDialog = false;
      try{
        await AddReserve(this.reserveSet)
        console.log('this.reserveSet', this.reserveSet.zoomStartTime)
        const payload = {
          "topic": this.reserveSet.item.title,
          "type": "2",
          "start_time": this.reserveSet.zoomStartTime,
          "timezone": "Asia/Tokyo",
          "settings": {
            "use_pmi": "false"
          },
          "uid": this.reserveSet.uid,
        }
        this.reserveSet.zoomUrl = await zoom(payload);
        const zoomUrlTemp = this.reserveSet.zoomUrl.data.split('?');
        this.reserveSet.zoomUrlLabel = zoomUrlTemp[0];
        this.reserveSet.zoomUrl = this.reserveSet.zoomUrl.data;
        // this.reserveSet.id = 'd-957a2dab508e4d6fbf2bdc0de8d1266b'; //SendGridのテンプレートID
        const customerMsg = {
          to: this.reserveSet.reserverMailAddress,
          from: "reservesystem@infom.jp",
          // bcc: keys.mailAddress,
          templateId: 'd-957a2dab508e4d6fbf2bdc0de8d1266b',
          uid: this.reserveSet.uid,
          item: this.reserveSet.item,
          name: this.reserveSet.name,
          dynamic_template_data: {
            name: this.reserveSet.name,
            // reserverMailAddress: this.reserveSet.reserverMailAddress,
            clientName: "",
            clientMailAddress: "",
            zoomUrl: this.reserveSet.zoomUrl,
            zoomUrlLabel: this.reserveSet.zoomUrlLabel,
            reserveDate: this.reserveSet.reserveDate,
            unreserveID: this.reserveSet.event.id,
            reserveCourceName: this.reserveSet.item.title,
            reserveCourcePrice: this.reserveSet.item.price,
          }
        };
        const response = await sendMailTemplate(customerMsg);
        console.log('response', response);
        console.log('reserveSet メール送信前', this.reserveSet)
        const clientMsg = {
          to: "",
          from: "",
          // bcc: keys.mailAddress,
          templateId: 'd-c8c9b63705de48bfb68171200c1e3277',
          uid: this.reserveSet.uid,
          item: this.reserveSet.item,
          dynamic_template_data: {
            name: this.reserveSet.name,
            reserverMailAddress: this.reserveSet.reserverMailAddress,
            clientName: "",
            clientMailAddress: "",
            zoomUrl: this.reserveSet.zoomUrl,
            zoomUrlLabel: this.reserveSet.zoomUrlLabel,
            reserveDate: this.reserveSet.reserveDate,
            unreserveID: this.reserveSet.event.id,
            reserveCourceName: this.reserveSet.item.title,
            reserveCourcePrice: this.reserveSet.item.price,
          },
        };
        const clientResponse = await sendmail(clientMsg);
        if (response && clientResponse) {
          console.log('メール送信成功')
          //本決済をする
          if(this.keys.SquarePayment){
            const result = await paymentSquareActual(this.reserveSet);
            console.log('paymentSquareActual result', result);
          }
          localStorage.removeItem('reserveSet');
          this.$emit('next-comp');
        } else if (response.data.body.errors) {
          console.log('メール送信失敗')
          console.log(response.data.body.errors)
          alert('エラーが発生しました。恐れ入りますが、最初から予約の取り直しをお願い致します。やり直しても引き続きエラーが発生する場合は、恐れ入りますが問い合わせをお願い致します。');
          if (this.keys.SquarePayment) {
            const result = await paymentSquareCancel(this.reserveSet);
            console.log('paymentSquareActual result', result);
          }
          this.backComp();
        }
      } catch (e){
        console.log('nextComp error',e);
        alert('エラーが発生しました。恐れ入りますが、最初から予約の取り直しをお願い致します。')
        if (this.keys.SquarePayment) {
          const result = await paymentSquareCancel(this.reserveSet);
          console.log('paymentSquareActual result', result);
        }
        this.backComp();
      }
      this.overlay = false;
    },
    async backComp(){
      console.log('backComp',this.reserveSet)
      const success1 = await SubReserveTemp(this.reserveSet)
      const success2 = await SubReserve(this.reserveSet)
      if(success1 && success2){
        localStorage.removeItem('reserveSet');
        this.$emit('back-comp');
      } else {
        alert('エラーが発生しました。恐れ入りますが、時間を置いて再度お試しください')
      }
    },
    async initializeCard(payments) {
      this.card = await payments.card();
      await this.card.attach('#card-container');
      console.log('this.card', this.card);
    },
    async handlePaymentMethodSubmission(event, paymentMethod) {
      event.preventDefault();
      try {
        // disable the submit button as we await tokenization and make a
        // payment request.
        this.cardButton = true;
        console.log('paymentMethod', paymentMethod);
        const token = await this.tokenize(paymentMethod);
        const paymentResults = await this.createPayment(token);
        this.displayPaymentResults('SUCCESS');
        console.debug('Payment Success', paymentResults);
      } catch (e) {
        this.cardButton = false;
        this.displayPaymentResults('FAILURE');
        console.error(e.message);
      }
    },
    async createPayment(token){
      const body = {
        sourceId: token,
        uid: this.reserveSet.uid,
        price: this.reserveSet.item.price,
      }
      const result = await paymentSquare(body);
      console.log('paymentSquare result',result);
      const parseResult = JSON.parse(result.data);
      console.log('paymentSquare result', parseResult);
      console.log('parseResult', parseResult)
      if (parseResult){
        console.log('決済成功')
        this.reserveSet.paymentID = parseResult.id;
        this.reserveSet.versionToken = parseResult.versionToken;
        this.nextComp();
      } else {
        alert('決済エラーが発生しました。恐れ入りますが時間を置いて再度試すか、別なカードをお試し下さい。')
        window.location.reload(false)
      }
    },
    async cardButtonClick(event) {
      this.squareToken = await createSquareToken(this.reserveSet.uid);
      console.log('squareToken', this.squareToken,event);
      console.log('paymentsの初期化',this.squareToken)
      await this.initializeCard(this.squareToken);
      // await this.handlePaymentMethodSubmission(event, this.card);
    },
    async paymentButtonClick(event) {
      await this.handlePaymentMethodSubmission(event, this.card);
    },  
    async firstLoad() {
      if (this.keys.SquareTestMode) {
        console.log('テストモード',this.keys);
        this.payments = await window.Square.payments(this.keys.squareAppIdTest, this.keys.squareLocationIdTest);
      } else {
        console.log('本番モード');
        this.payments = await window.Square.payments(this.keys.squareAppId, this.keys.squareLocationId);
      }
      console.log('paymentsの初期化',this.payments)
      await this.initializeCard(this.payments);
    },
    async tokenize(paymentMethod) {
      const tokenResult = await paymentMethod.tokenize();
      if (tokenResult.status === 'OK') {
        return tokenResult.token;
      } else {
        let errorMessage = `Tokenization failed-status: ${tokenResult.status}`;
        if (tokenResult.errors) {
          errorMessage += ` and errors: ${JSON.stringify(
            tokenResult.errors
          )}`;
        }
        throw new Error(errorMessage);
      }
    },
    displayPaymentResults(status) {
      const statusContainer = document.getElementById(
        'payment-status-container'
      );
      if (status === 'SUCCESS') {
        statusContainer.classList.remove('is-failure');
        statusContainer.classList.add('is-success');
      } else {
        statusContainer.classList.remove('is-success');
        statusContainer.classList.add('is-failure');
      }

      statusContainer.style.visibility = 'visible';
    }
  },
  created(){
    console.log("reserveSet",this.reserveSet);
    // this.firstLoad();
  }
}
</script>