<template>
  <v-container>
    <v-row>
      <v-col>
        <component
          :is="selectComp"
          :reserveList="reserveList"
          :reserveSet="reserveSet"
          @next-comp="nextComp"
          @back-comp="backComp"
          @send-confirmation="sendConfirmation"
        />
      </v-col>
    </v-row>
    <!-- <v-row>
      <v-col>
        <v-select
          v-model="selectComp"
          :items="reserveComp"
          item-text="text"
          item-value="value"
        />
      </v-col>
      <v-col>
        <v-btn
          @click="nextComp"
        >次</v-btn>
      </v-col>
      <v-col>
        <v-btn
          @click="backComp"
        >
          前
        </v-btn>
      </v-col>
    </v-row> -->
  </v-container>

</template>

<script>
import listReserve from '@/components/listReserve.vue'
import calendarReserve from '@/components/calendarReserve.vue'

export default {
  props:['reserveList','reserveSet'],
  data(){
    return {
      selectComp:calendarReserve,
      reserveComp:[
        {
          text:'リスト表示',
          value:listReserve,
        },
        {
          text:'カレンダー表示',
          value:calendarReserve,
        }
      ]
    }
  },
  methods:{
    nextComp(){
      this.$emit('next-comp');
    },
    backComp(){
      this.$emit('back-comp');
    },
    sendConfirmation(event){
      this.$emit('send-confirmation',event)
    },

  }
}
</script>