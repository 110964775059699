<template>
  <v-data-table :items="reserver" :headers="headers">
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>予約一覧</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-dialog v-model="show" width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-on="on" v-bind="attrs" @click="show = !show">
                <v-icon>
                  mdi-help-circle-outline
                </v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-text>
                予約が完了した人の一覧です。予約編集の欄の <v-icon small>mdi-pencil</v-icon> で各種編集やZOOM
                URLの確認、<v-icon small>mdi-delete</v-icon> で予約のキャンセルが出来ます。ただし料金の返金は出来ませんので、こちらは別途ご対応をお願いします。
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar-items>
        <v-dialog v-model="dialog" max-width="500px">
          <!-- <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              新規予約
            </v-btn>
          </template> -->
          <v-card>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.name" label="お名前"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.reserverMailAddress" label="メールアドレス"></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.start" label="予約日（変更不可）" readonly></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-text-field v-model="editedItem.zoomURL" label="ZOOM URL（変更不可）" readonly></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                キャンセル
              </v-btn>
              <v-btn color="blue darken-1" text @click="save">
                保存する
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">この予約をキャンセルしてよろしいですか?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">いいえ</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">はい</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
import { loadReserveList,saveReserverData,SubReserve,SubReserveTemp } from '@/modules/OperationFirebase.js';
import dayjs from 'dayjs';

export default {
  props:["uid"],
  data(){
    return {
      reserver:[],
      headers:[
        {
          text:'お名前',
          align:"start",
          value:'name'
        },
        {
          text:'メールアドレス',
          value:'reserverMailAddress'
        },
        {
          text:'予約日',
          value:'start'
        },
        {
          text:'予約編集',
          value:'actions',
          sortable:false,
        }
      ],
      show:false,
      dialog:false,
      dialogDelete:false,
      editedItem:"",
    }
  },
  methods:{
    async loadReserveList(){
      console.log("loadReserveList")
      this.reserver = await loadReserveList(this.uid);
      console.log('reserver',this.reserver);
      this.dateSetting();
      console.log('reserver',this.reserver);
    },
    dateSetting(){
      this.reserver = this.reserver.map(doc => {
        console.log('doc',doc)
        doc.start = dayjs.unix(doc.start.seconds).format('YYYY-MM-DD HH:mm');
        return doc;
      })
    },
    editItem (item) {
        this.editedIndex = this.reserver.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
          this.editedIndex = this.reserver.indexOf(item)
          this.editedItem = Object.assign({}, item)
          console.log('this.editedItem',this.editedItem)
          this.dialogDelete = true
          console.log(this.editedItem.reserveId)
      },

      async deleteItemConfirm () {
        const unreserveSet = {
          uid:this.uid,
          event:{
            id:this.editedItem.reserveId,
          }
        }
        await SubReserveTemp(unreserveSet);
        await SubReserve(unreserveSet);
        this.reserver.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          console.log('this.editedItem',this.editedItem)
          const reserveSet = {
            event:{
              id : this.editedItem.reserveId,
            },
            name:this.editedItem.name,
            reserverMailAddress:this.editedItem.reserverMailAddress,
            uid:this.uid,
          }
          saveReserverData(reserveSet);
          Object.assign(this.reserver[this.editedIndex], this.editedItem)
        } else {
          this.reserver.push(this.editedItem)
        }
        this.close()
      },
  },
  created(){
    this.loadReserveList();
  }
}
</script>