<template>
  <v-sheet>
    <v-app-bar-nav-icon v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm || $vuetify.breakpoint.md"
      @click.stop="drawer = !drawer">
    </v-app-bar-nav-icon>
    <v-navigation-drawer
    v-model="drawer"
    app>
      <v-list>
        <v-list-item v-for="(item,index) in items" :key="'navi'+index" @click="changeComp(index)">
          <v-list-item-title>{{item.title}}</v-list-item-title>
        </v-list-item>
      </v-list>
      <v-spacer></v-spacer>
      <v-list>
        <v-list-item @click="userLogout(logoutTitle)">
          <v-list-item-title>ログアウト</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-container fill-height>
      <v-row align-content="center" justify="center">
        <v-col align="center">
          <v-card v-if="!userLogin">
            <v-card-text>
              <v-row justify="center">
                <v-col align="right" align-self="center" cols="3">
                  メールアドレス
                </v-col>
                <v-col align="left" cols="4">
                  <v-text-field v-model="mail"></v-text-field>
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col align="right" align-self="center" cols="3">
                  パスワード
                </v-col>
                <v-col align="left" cols="4">
                  <v-text-field v-model="pass" type="password"></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="login">
                ログインする
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
          <component v-else :is="items[compIndex].comp" :uid="userLogin"></component>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script>
import addReserveDay from './AddReserveDay.vue'
import setData from './setData.vue'
import reserverEdit from './reserverEdit.vue'
import setItems from './setItems.vue'
import {getUid,loginCheck,userLogout} from '@/modules/OperationFirebase.js';

export default {
  components:{
    addReserveDay,
    setData,
    reserverEdit,
    setItems,
  },
  data(){
    return {
      uid:'',
      userLogin:false,
      mail:'',
      pass:'',
      items:[
        {
          title:'予定日追加',
          comp:addReserveDay,
          icon:"",
        },
        {
          title:'予約一覧',
          comp:reserverEdit,
          icon:"",
        },
        {
          title:'コース一覧',
          comp:setItems,
          icon:"",
        },
        {
          title:'設定',
          comp:setData,
          icon:"",
        },
      ],
      drawer: null,
      compIndex:0,
    }
  },
  methods:{
    async login(){
      try{
        this.userLogin = await getUid(this.mail,this.pass);
      }catch(e){
        console.log('error',e);
        alert('メールアドレス又はパスワードに間違いがあります。')
      }
      
    },
    async logincheck(){
      this.userLogin = await loginCheck();
      console.log('userLogin',this.userLogin)
      if(this.userLogin){
        this.uid = this.userLogin.uid;
      }
    },
    async userLogout(){
      const result = confirm("ログアウトしてよろしいですか？");
      if(result){
        this.userLogin = await userLogout();
      }
    },
    changeComp(index){
      // this.drawer = false;
      // console.log('drawer',this.drawer)
      this.compIndex = index;
    },
  },
  created(){
    this.logincheck();
  }
}
</script>