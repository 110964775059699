<template>
<v-container fill-height>
  <v-row
    align-content="center"
    justify="center"
  >
    <v-col
      align="center"
    >
      <v-card
        width="600"
      >
        <v-card-text>
          <v-snackbar
            v-model="flag.duplicate"
          >
            そのメールアドレスは登録済みです
          </v-snackbar>
          <v-row
            align-content="center"
            justify="center"
          >
            <v-col
              align="right"
              align-self="center"
              cols="3"
            >
              お名前
            </v-col>
            <v-col
              align="left"
              cols="6"
            >
              <v-text-field
                v-model="register.name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row
            align-content="center"
            justify="center"
          >
            <v-col
              align="right"
              align-self="center"
              cols="3"
            >
              メールアドレス
            </v-col>
            <v-col
              align="left"
              cols="6"
            >
              <v-text-field
                v-model="register.mail"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="registerUser"
          >
            登録する
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
import { duplicateMailCheck } from "../modules/OperationFirebase";

export default {
  data(){
    return {
      register:{
        name:null,
        mail:null,
      },
      flag:{
        duplicate:false,
      }
    }
  },
  methods:{
    async registerUser(){
      console.log('新規登録処理')
      // const check = await this.duplicateCheck();
      // console.log('check',check)
      // if(!check.length){
      //   console.log('登録開始')
      //   localStorage.setItem('register',JSON.stringify(this.register))
      //   this.$router.push({
      //       path:'Payment',
      //       props:this.register
      //   })
      // } else {
      //   this.flag.duplicate = true;
      // }
      console.log('登録開始')
        localStorage.setItem('register',JSON.stringify(this.register))
        this.$router.push({
            path:'Payment',
            props:this.register
        })
    },
    async duplicateCheck(){
      console.log('登録重複確認')
      const check = await duplicateMailCheck(this.register);
      console.log(check);
      return check;
    }
  }
}
</script>