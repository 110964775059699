<template>
  <v-container>
    <v-row>
      <v-col>
        <v-radio-group
          v-model="selectDate"
        >
          <v-radio
            v-for="(list,index) in reserveList"
            :key="index"
            :label="list.reserveSet.name"
            :value="list.reserveDate"
          >
          </v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  // props:['reserveList','reservationList'],
  data(){
    return {
      selectDate:'',
      reserveList:[
        {
          reserveExist:true,
          reserveSet:{
            start:'',
            end:'',
            reserveDate:'',
            name:'',
            mailAddress:'',
          },
        }
      ]
    }
  }
}
</script>