<template>
  <v-container fill-height>
    <v-row
      align-content="center"
      justify="center"
    >
      <v-col
        align="center"
      >
        <v-progress-circular
          color="primary"
          :size="70"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>
    <stripe-checkout
      ref="checkoutRef"
      :pk="publishableKey"
      :session-id="sessionId"
      :customerEmail="register.mail"
    />
  </v-container>
  
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import { sessionCreate } from '../modules/CloudFunctions.js';
export default {
  components: {
    StripeCheckout,
  },
  data () {
    return {
      register:null,
      publishableKey:"pk_test_51HplX4K7XL4O73u5F9MXt1Da87E1eJrOXBnrcyFs94zBl5EgUQrejekxSFAhe2gb16bbqlLg3SUG84KmD75RP77H000sJc8vUh",
      sessionData:{
        "success_url": "http://localhost:8080/createUser?mail=",
        "cancel_url": "http://localhost:8080/register",
        "payment_method_types": [
          "card"
        ],
        "line_items": [
          {
            "price": "price_1L0gSHK7XL4O73u5RU1cCp3T",
            "quantity": 1
          }
        ],
        "customer_email":null,
        "mode": "subscription"
      },
      sessionId: null,
    };
  },
  methods: {
    async createSession() {
      console.log('createSession')
      const session = await sessionCreate(this.sessionData);
      this.sessionId = await session.data.id;
      console.log(this.sessionId);
      this.$refs.checkoutRef.redirectToCheckout();
    }
  },
  mounted(){
    this.createSession();
  },
  created(){
    this.register = JSON.parse(localStorage.getItem("register"));
    this.sessionData.customer_email = this.register.mail;
    this.sessionData.success_url += this.register.mail;
    console.log('this.register',this.register)
  }
};
</script>