import { app } from "./OperationFirebase.js";
// import { getFunctions,httpsCallable,connectFunctionsEmulator } from 'firebase/functions';
import { getFunctions,httpsCallable } from 'firebase/functions';

const region = 'asia-northeast1'; //リージョン指定
const functions = getFunctions(app, region);
// connectFunctionsEmulator(functions,"localhost",5000);

// export async function cloudFunctionSgtest(){
//   console.log('sgtest')
//   const sgtest = httpsCallable(functions,'sgtest');
//   return sgtest();
// }

export async function zoom(payload){
  console.log('payload',payload);
  const zoom = httpsCallable(functions,'getZoomID');
  const zoom_URL = await zoom(payload)
  console.log('zoom_URL',zoom_URL);
  return zoom_URL;
}

export async function getZoomAuthirzationCode(reserveSet){
  console.log('getZoomAuthirzationCode')
  const getZoomAuthirzationCode = httpsCallable(functions,'getAuthUrl');
  const result = await getZoomAuthirzationCode(reserveSet)
  console.log('zoomAuthirzationCode',result);
  return result.data.zoomAuthUrl;
}

export async function sendmail(reserveSet){
  console.log('メール送信 reserveSet',reserveSet)
  const sendmail = httpsCallable(functions,'sendmail');
  return sendmail(reserveSet);
}

export async function sessionCreate(sessionData){
  console.log('sessionData',sessionData)
  const sessionCreate = httpsCallable(functions,'sessions');
  return sessionCreate(sessionData);
}

export async function sendMailTemplate(data){
  console.log('sendMailTemplate')
  const sendmail = httpsCallable(functions,'sendMailTemplate');
  return sendmail(data);
}

export async function paymentSquare(data){
  console.log('paymentSquare')
  const payment = httpsCallable(functions,'paymentSquare');
  return payment(data);
}

export async function paymentSquareActual(data){
  console.log('paymentSquareActual')
  const payment = httpsCallable(functions,'paymentSquareActual');
  return payment(data);
}

export async function paymentSquareCancel(data){
  console.log('paymentSquareCancel')
  const payment = httpsCallable(functions,'paymentSquareCancel');
  return payment(data);
}