<template>
  <v-container>
    <v-row
      align-content="center"
    >
      <v-col
        align="center"
      >
        <v-card
          max-width="400px"
        >
          <v-card-title>
            予約日：{{this.reserveSet.start}}
          </v-card-title>
          <v-card-text
            align="left"
          >
            この予約をキャンセルしますが、よろしいですか？
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="unReserveSubmit"
            >
              予約をキャンセルする
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { UnReserve,SubReserve,SubReserveTemp } from "../modules/OperationFirebase";
import dayjs from "dayjs"

export default {
  data(){
    return {
      reserveSet:{},
      unreserveSet:{},
    }
  },
  methods:{
    async unReserve(unreserveSet){
      this.reserveSet = await UnReserve(unreserveSet);
      this.reserveSet = this.reserveSet.data();
      console.log('this.reserveSet',this.reserveSet);
      this.reserveSet.start = dayjs.unix(this.reserveSet.start.seconds).toDate();
      this.reserveSet.start = dayjs(this.reserveSet.start).format('YYYY年MM月D日 HH時mm分～')
    },
    async unReserveSubmit(){
      console.log('this.reserveSet',this.reserveSet)
      await SubReserveTemp(this.unreserveSet);
      await SubReserve(this.unreserveSet);
      alert('予約をキャンセルしました');
      this. $router.push({path:'/?uid='+this.unreserveSet.uid})
    }
  },
  created(){
    this.unreserveSet.uid = this.$route.query.uid;
    this.unreserveSet.id = this.$route.query.id;
    this.unReserve(this.unreserveSet);
  }
}
</script>