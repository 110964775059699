<template>
  <v-container fill-height>
    <v-row
      justify="center"
    >
      <v-col
        align="center"
      >
        <v-card
          elevation="0"
          width="600px"
        >
          <v-card-title
            class="text-center"
          >
            ご予約が完了しました！
          </v-card-title>
          <v-card-text
            align="left"
          >
            ご入力頂いているメールアドレス宛に、確認のメールと当日のZOOMミーティングのURLをお送りしておりますので、ご確認をお願い致します。<br><br>
            もしメールが届かない場合は、もう少し時間を置いていただくか、迷惑メールフォルダに入っていないかご確認下さい。<br><br>
            また万が一、予約日時間違いや急な予定等により、キャンセルをしたい場合は、メール内にキャンセル方法を記載しておりますので、ご確認下さい。
          </v-card-text>
          <v-card-actions>
            <v-btn
              @click="backComp"
            >
              予約カレンダーに戻る
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import {saveReserverData} from '@/modules/OperationFirebase.js';

export default {
  props:["reserveSet"],
  methods:{
    nextComp(){
      this.$emit('next-comp');
    },
    backComp(){
      console.log('backComp')
      this.$emit('back-firstcomp');
    },
    saveReserverData(){
      const result = saveReserverData(this.reserveSet);
      return result;
    }
  },
  mounted(){
    this.saveReserverData();
  }
}
</script>